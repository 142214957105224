var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%", "background-color": "#FFFFFF" } },
    [
      _c("head-layout", {
        attrs: {
          "head-title":
            this.formType == "edit"
              ? _vm.$t("cip.plat.sys.dataStandard.title.editHeadTitle")
              : this.formType == "view"
              ? _vm.$t("cip.plat.sys.dataStandard.title.viewHeadTitle")
              : _vm.$t("cip.plat.sys.dataStandard.title.addHeadTitle"),
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave(false)
          },
          "head-saveBack": function ($event) {
            return _vm.headSave(true)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("form-layout", {
        ref: "formLayout",
        attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
        on: {
          "update:dataForm": function ($event) {
            _vm.dataForm = $event
          },
          "update:data-form": function ($event) {
            _vm.dataForm = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }